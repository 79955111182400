import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../../src/Pages/dashboard";
import Auth from "../../src/Pages/auth";
import PaymentCycle from "../components/Dashboard/PaymentCycle";
import DefinePaymentCycle from "../components/Dashboard/DefinePaymentCycle";

const Paths = () => {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Auth />}></Route>
        <Route path="/dashboard/" element={<Dashboard />}>
          <Route path="payment-cycle" element={<PaymentCycle />} />
          <Route path="payment-cycle-define" element={<DefinePaymentCycle />} />
        </Route>
      </Routes>
    </>
  );
};
export default Paths;
