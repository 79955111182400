import React, { useState, useEffect } from "react";
import service from "../../services/axiosService";
import { ToastContainer, toast } from "react-toastify";
import { SpinnerCircular } from "spinners-react";
import {
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Checkbox,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const DefinePaymentCycle = () => {
  const [startDate, setStartDate] = useState("");
  const [loader, setLoader] = useState("");
  const [rowData, setRowData] = useState("");
  const [paymentCycle, setPaymentCycle] = useState(14);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    setLoader(true);
    loadPaymentCycles();
  }, []);

  const loadPaymentCycles = async () => {
    try {
      const res = await service.get(
        process.env.REACT_APP_URL +
          "/" +
          process.env.REACT_APP_API +
          "/payment-cycles?populate=* "
      );
      setRowData(res.data);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };
  const generatePaymentCycles = async (startDate, duration) => {
    try {
      const start = new Date(startDate);
      const year = start.getFullYear();
      const cycles = [];

      for (let month = start.getMonth(); month < 12; month++) {
        const startOfMonth = new Date(year, month, start.getDate());
        const endOfMonth = new Date(year, month + 1, 0);
        let cycleStartDate = new Date(startOfMonth);
        let cycleEndDate = new Date(startOfMonth);

        while (cycleStartDate <= endOfMonth) {
          const cycle = {
            startDate: cycleStartDate.toISOString().split("T")[0],
            endDate: new Date(
              cycleEndDate.setDate(cycleEndDate.getDate() + parseInt(duration))
            )
              .toISOString()
              .split("T")[0],
            status: "Pending",
            IsActive: true,
            orders: [],
          };
          cycles.push(cycle);
          cycleStartDate = new Date(
            cycleEndDate.setDate(cycleEndDate.getDate() + 1)
          );
          cycleEndDate = new Date(cycleStartDate);
        }
      }

      const payload = cycles.map((cycle) => ({ data: cycle }));

      await Promise.all(
        payload.map((p) =>
          service.post(
            `${process.env.REACT_APP_URL}/${process.env.REACT_APP_API}/payment-cycles`,
            p
          )
        )
      );

      loadPaymentCycles();
    } catch (err) {
      console.log(err);
    }
  };

  const handleGenerate = () => {
    if (!startDate) {
      toast.error("select start date");
    } else {
      generatePaymentCycles(startDate, paymentCycle);
    }
  };

  const handleSave = () => {
    console.log("ok");
  };
  // Handle edit icon click
  const handleEditIconClick = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Handle payment cycle change
  const handlePaymentCycleChange = (event) => {
    setPaymentCycle(event.target.value);
  };

  return (
    <div>
      <h2>Define Payment Cycle</h2>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <FormControl style={{ marginRight: "20px" }}>
          <label htmlFor="start-date" style={{ marginRight: "10px" }}>
            Start Date:
          </label>
          <input
            type="date"
            id="start-date"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </FormControl>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <FormControl style={{ marginRight: "20px" }}>
          <label htmlFor="payment-cycle" style={{ marginRight: "10px" }}>
            Duration:
          </label>
          <Select
            id="payment-cycle"
            value={paymentCycle}
            onChange={handlePaymentCycleChange}
            style={{
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          >
            <MenuItem value={7}>7 days</MenuItem>
            <MenuItem value={14}>14 days</MenuItem>
            <MenuItem value={30}>30 days</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleGenerate}
          style={{ backgroundColor: "#DC3B05" }}
        >
          Generate
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Check</TableCell>
              <TableCell align="right">ID</TableCell>
              <TableCell align="right">Start Date</TableCell>
              <TableCell align="right">End Date</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Review</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loader ? (
              <TableRow>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  {" "}
                  <SpinnerCircular />
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            ) : rowData.length ? (
              rowData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Checkbox />
                  </TableCell>
                  <TableCell align="right">{row.id}</TableCell>
                  <TableCell align="right">
                    {row.attributes.startDate}
                  </TableCell>
                  <TableCell align="right">{row.attributes.endDate}</TableCell>
                  <TableCell align="right">{row.attributes.status}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditIconClick(row)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">no record</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Modal */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Payment Cycle:1-2024</DialogTitle>
        <DialogContent>
          {selectedRow && (
            <div>
              <p>
                <strong>Vendor Id:</strong> 1234 {selectedRow.storeName}
              </p>
              <p>
                <strong>Store Name:</strong> Reena Kitchen
                {selectedRow.storeName}
              </p>
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <p>
                  <strong>Address:</strong>101 JASPER AVE SW. 25-108 CEDAR ST.
                  SUITE 5 BIRCHMOUNT RD. {selectedRow.address}
                </p>
                <p>
                  <strong>Contact Number:</strong>1-800-622-6232{" "}
                  {selectedRow.contactNumber}
                </p>
                <p>
                  <strong>Bank Account Number:18006226232</strong>{" "}
                  {selectedRow.bankAccountNumber}
                </p>
              </div>
              <FormControlLabel
                control={<Checkbox />}
                label="Verify Bank Details"
                style={{ marginBottom: "10px" }}
              />
              <p>
                <strong>Amount Pending:</strong>
              </p>
              <input
                type="text"
                style={{
                  marginBottom: "10px",
                  padding: "10px",
                  marginRight: "20px",
                }}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: "10px" }}
              >
                Paid
              </Button>
              <p>
                <strong>Amount Paying:</strong>
              </p>
              <input
                type="text"
                style={{ marginBottom: "10px", padding: "10px" }}
              />
              <p>
                <strong>Transaction ID:</strong>
              </p>
              <input
                type="text"
                style={{ marginBottom: "10px", padding: "10px" }}
              />
              <p>
                <strong>Payment Date and Time:</strong>
              </p>
              <input
                type="datetime-local"
                style={{ marginBottom: "10px", padding: "10px" }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained">
            Close
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
};

export default DefinePaymentCycle;
