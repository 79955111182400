import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Auth.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import GoogleLogo from "../../../assets/images/google.png";
import axios from "axios";
const SignUp = () => {
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    lname: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("First Name is required"),
    lname: Yup.string().required("Last Name is required"),
    mobile: Yup.number().required("Mobile Number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values) => {
    const payload = {
      firstName: values.name,
      LastName: values.lname,
      email: values.email,
      Customer_Type_Auth: "Orderingo",
      cellPhone: values.mobile.toString(),
      password: values.password,
      user_type: "Orderingo",
      userType: "Orderingo",
      username: values.name + values.lname,
    };
    await axios.post(`/api/auth/local/register`, {
        ...payload,
      })
      .then((res) => {
        localStorage.setItem("id", res.data.user.id);
        localStorage.setItem("userName", res.data.user.username);
        localStorage.setItem("status", res.data.user.profileStatus);
        localStorage.setItem("phone", res.data.user.cellPhone);
        localStorage.setItem("token", res.data.jwt);
        localStorage.setItem("cell_phone", values.mobile);
        localStorage.setItem("identifier", res?.data?.user?.email);
        localStorage.setItem("password", payload.password);
        localStorage.setItem("fName", res?.data?.user?.firstName);
        localStorage.setItem("newCustomer", res?.data?.user?.newCustomer);
        localStorage.setItem(
          "NewUserUsageCount",
          res.data.user.NewUserUsageCount
        );
        navigate("/dashboard");
        toast.success("Signup Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="signup-page">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className="signup-form">
          <div className="form-group">
            <label htmlFor="name">First Name</label>
            <Field type="text" id="name" name="name" className="data" />
            <ErrorMessage
              name="name"
              component="div"
              className="error-message"
            />
          </div>
          <div className="form-group">
            <label htmlFor="lname">Last Name</label>
            <Field type="text" id="lname" name="lname" className="data" />
            <ErrorMessage
              name="lname"
              component="div"
              className="error-message"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Field type="email" id="email" name="email" className="data" />
            <ErrorMessage
              name="email"
              component="div"
              className="error-message"
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobile">Mobile Number</label>
            <Field type="number" id="mobile" name="mobile" className="data" />
            <ErrorMessage
              name="mobile"
              component="div"
              className="error-message"
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Field
              type="password"
              id="password"
              name="password"
              className="data"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="error-message"
            />
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Field
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              className="data"
            />
            <ErrorMessage
              name="confirmPassword"
              component="div"
              className="error-message"
            />
          </div>

          <button className="orange data" type="submit">
            Sign Up
          </button>
          <div className="labeled-line">
            <div className="line"></div>
            <label>or</label>
            <div className="line"></div>
          </div>
          <button type="submit" className="blue data">
            {" "}
            <img
              src={GoogleLogo}
              alt="Google Logo"
              className="google-logo"
            />{" "}
            Continue with Google
          </button>
        </Form>
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default SignUp;
