import React, { useEffect, useState } from "react";
import service from "../../services/axiosService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Box,
  IconButton,
  Modal,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PaymentCycleDetail from "./PaymentCycleDetail";

const inputStyles = {
  padding: "8px",
  marginRight: "20px",
  border: "1px solid #ccc",
  borderRadius: "5px",
};
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const PaymentCycle = () => {
  const [paymentCycle, setPaymentCycle] = useState();
  const [orders, setOrders] = useState();
  const [selectedRange, setSelectedRange] = useState(null);
  const [filterOrders, setFilteredOrders] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [storeId, setStoreId] = useState(null);
  useEffect(() => {
    loadPaymentCycles();
    loadOrders();
  }, []);
  const handleModal = (id) => {
    setStoreId(id);
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const loadPaymentCycles = async () => {
    try {
      const res = await service.get(
        process.env.REACT_APP_URL +
          "/" +
          process.env.REACT_APP_API +
          "/payment-cycles?populate=*"
      );

      setPaymentCycle(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const loadOrders = async () => {
    try {
      const res = await service.get(
        process.env.REACT_APP_URL +
          "/" +
          process.env.REACT_APP_API +
          "/orders?populate=* "
      );

      setOrders(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getDateRangeOptions = () => {
    const options = [];

    paymentCycle?.forEach((cycle) => {
      const startDate = cycle.attributes.startDate;
      const endDate = cycle.attributes.endDate;
      const label = `${startDate} - ${endDate}`;

      options.push({
        value: `${startDate}-${endDate}`,
        label: label,
      });
    });

    return options;
  };

  const handleRangeChange = (e) => {
    const range = e.target.value.split("-");
    const startDateParts = range.slice(0, 3);
    const endDateParts = range.slice(3);

    const startYear = parseInt(startDateParts[0]);
    const startMonth = parseInt(startDateParts[1]) - 1;
    const startDay = parseInt(startDateParts[2]);
    const startDate = new Date(startYear, startMonth, startDay);

    const endYear = parseInt(endDateParts[0]);
    const endMonth = parseInt(endDateParts[1]) - 1;
    const endDay = parseInt(endDateParts[2]);
    const endDate = new Date(endYear, endMonth, endDay);

    // Filter orders based on delivery date range
    const filteredOrders = orders.filter((order) => {
      const deliveryDate = new Date(order.attributes.deliveryDate);
      return deliveryDate >= startDate && deliveryDate <= endDate;
    });

    // Group orders by store ID and calculate the sum of totalAmount
    const storeSumMap = new Map();
    console.log("filteredOrders", filteredOrders);
    filteredOrders.forEach((order) => {
      const storeId = order.attributes.store.data.id;
      const storeName = order.attributes.store.data.attributes.storeName;
      const totalAmount = order.attributes.totalAmount;
      const store = order.attributes.store.data;
      if (storeSumMap.has(storeId)) {
        storeSumMap.get(storeId).totalAmount += totalAmount;
      } else {
        storeSumMap.set(storeId, {
          storeId: storeId,
          store: store,
          storeName: storeName,
          totalAmount: totalAmount,
        });
      }
    });

    // Convert the map to an array of summed orders
    const summedOrders = Array.from(storeSumMap.values());

    // Update filtered orders and selected range
    setFilteredOrders(summedOrders);
    setSelectedRange({ startDate, endDate });
  };

  return (
    <>
      <h2>Payment Cycles</h2>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "space-between",
        }}
      >
        <h3>Payment Cycle:</h3>
        <Box sx={{ marginLeft: "10px" }}>
          {" "}
          <FormControl>
            <select
              id="dateRange"
              onChange={(e) => {
                handleRangeChange(e);
              }}
              style={inputStyles}
            >
              {getDateRangeOptions().map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "space-between",
        }}
      >
        <Box>
          <h3>Status:</h3>
        </Box>
        <Box sx={{ marginLeft: "10px" }}>
          {" "}
          <p>Open</p>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#dc3b05", color: "white" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Vendor ID</TableCell>
              <TableCell align="right" sx={{ color: "white" }}>
                Vendor Name
              </TableCell>
              <TableCell align="right" sx={{ color: "white" }}>
                Total Payable
              </TableCell>
              <TableCell align="right" sx={{ color: "white" }}>
                Status
              </TableCell>
              <TableCell align="right" sx={{ color: "white" }}>
                View Balance Sheet
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterOrders?.length ? (
              filterOrders.map((row) => (
                <TableRow
                  key={row.storeId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.storeId}
                  </TableCell>
                  <TableCell align="right">{row.storeName}</TableCell>
                  <TableCell align="right">{row.totalAmount}</TableCell>
                  <TableCell align="right">pending</TableCell>
                  <TableCell align="right">
                    {" "}
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleModal(row)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">no records</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={isOpen} onClose={handleClose}>
        <PaymentCycleDetail row={storeId} handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default PaymentCycle;
