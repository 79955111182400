import React from "react";
import { Link, Outlet, useLocation, Route, Routes } from "react-router-dom";
import {
  AppBar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import PaymentCycle from "../components/Dashboard/PaymentCycle";
import Menu1 from "../components/Dashboard/DefinePaymentCycle";

const drawerWidth = 240;

const Dashboard = () => {
  const appBarStyle = {
    backgroundColor: "#DC3B05",
    zIndex: 1201,
  };

  const drawerStyle = {
    width: drawerWidth,
    flexShrink: 0,
  };

  const drawerPaperStyle = {
    width: drawerWidth,
  };

  const contentStyle = {
    flexGrow: 1,
    padding: "20px",
  };

  const location = useLocation();

  const isSelected = (path) => {
    return location.pathname === path;
  };

  const listItemStyle = (path) => {
    return isSelected(path)
      ? { backgroundColor: "#DC3B05", color: "white" }
      : {};
  };

  return (
    <div style={{ display: "flex" }}>
      <AppBar position="fixed" style={appBarStyle}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Orderingo
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        style={drawerStyle}
        variant="permanent"
        classes={{ paper: drawerPaperStyle }}
        anchor="left"
      >
        <Toolbar />
        <div style={{ width: drawerWidth }}>
          <List>
          <ListItem
              button
              component={Link}
              to="/dashboard/payment-cycle-define"
              style={listItemStyle("/dashboard/payment-cycle-define")}
            >
              <ListItemText primary="Define Payment Cycle" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/dashboard/payment-cycle"
              style={listItemStyle("/dashboard/payment-cycle")}
            >
              <ListItemText primary="Payment Cycle" />
            </ListItem>
          
            {/* <ListItem
              button
              component={Link}
              to="/dashboard/menu2"
              style={listItemStyle("/dashboard/menu2")}
            >
              <ListItemText primary="Menu 2" />
            </ListItem> */}
          </List>
        </div>
      </Drawer>
      <main style={contentStyle}>
        <Toolbar />
        <Routes>
          {/* Define route for Payment Cycle */}
          <Route path="/dashboard/payment-cycle" element={<PaymentCycle />} />
          <Route path="/dashboard/payment-cycle-define" element={<Menu1 />} />
          {/* Add routes for Menu 1 and Menu 2 components here */}
        </Routes>
        <Outlet />
      </main>
    </div>
  );
};

export default Dashboard;
