import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Auth.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import GoogleLogo from "../../../assets/images/google.png";
import axios from "axios";
const SignIn = () => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const handleSubmit = async (values) => {
    const payload = {
      identifier: values.email,
      password: values.password,
    };
    await axios
      .post(
        process.env.REACT_APP_URL +
          "/" +
          process.env.REACT_APP_API +
          "/auth/local",
        payload
      )
      .then((res) => {
        localStorage.setItem("id", res.data.user.id);
        localStorage.setItem("status", res.data.user.profileStatus);
        localStorage.setItem("userName", res.data.user.username);
        localStorage.setItem("identifier", values.email);
        localStorage.setItem("password", values.password);
        localStorage.setItem("token", res.data.jwt);
        localStorage.setItem("fName", res.data.user.firstName);
        localStorage.setItem("newCustomer", res.data.user.newCustomer);
        localStorage.setItem(
          "NewUserUsageCount",
          res.data.user.NewUserUsageCount
        );
        if (res.data.user.Customer_Type_Auth === "Orderingo") {
          navigate("/dashboard");
          toast.success("SignIn Successfully");
        } else {
          toast.error("Invalid Permission");
        }
      })
      .catch((err) => {
        if (
          err.response.data.data[0].messages[0].message ===
          "Identifier or password invalid."
        ) {
          toast.error(err.response.data.data[0].messages[0].message);
        } else {
          toast.error("error");
        }
      });
  };

  return (
    <div className="signup-page">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className="signup-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Field type="email" id="email" name="email" className="data" />
            <ErrorMessage
              name="email"
              component="div"
              className="error-message"
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Field
              type="password"
              id="password"
              name="password"
              className="data"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="error-message"
            />
          </div>

          <button className="orange data" type="submit">
            Sign In
          </button>
          <div className="labeled-line">
            <div className="line"></div>
            <label>or</label>
            <div className="line"></div>
          </div>
          <button type="submit" className="blue data ">
            {" "}
            <img
              src={GoogleLogo}
              alt="Google Logo"
              className="google-logo"
            />{" "}
            Continue with Google
          </button>
        </Form>
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default SignIn;
