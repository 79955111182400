import axios from "axios";

class axiosService {
  constructor() {
    this.api = axios.create();

    this.api.interceptors.response.use(
      this.handleSuccessResponse,
      this.handleErrorResponse
    );

    this.api.interceptors.request.use(
      this.handleRequestConfig,
      this.handleRequestError
    );
  }

  handleSuccessResponse(response) {
    return response.data;
  }

  handleErrorResponse(error) {
    // Handle error responses here
    throw error;
  }
  handleRequestConfig(config) {
    if (!config.headers.Authorization) {
      // const token = localStorage.getItem("token");
      // if (token) {
      config.headers.Authorization = `${process.env.REACT_APP_TOKEN}`;
      // }
    }

    return config;
  }

  handleRequestError(error) {
    throw error;
  }

  get(url, config = {}) {
    return this.api.get(url, config);
  }

  post(url, data = {}, config = {}) {
    return this.api.post(url, data, config);
  }

  put(url, data = {}, config = {}) {
    return this.api.put(url, data, config);
  }

  delete(url, config = {}) {
    return this.api.delete(url, config);
  }
}

export default new axiosService();
