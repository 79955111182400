import React, { useEffect, useState } from "react";
import service from "../../services/axiosService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CrossIcon from "@mui/icons-material/Clear";
function PaymentCycleDetail({ row = null, handleClose }) {
  const [orders, setOrders] = useState();
  useEffect(() => {
    const loadOrders = async () => {
      try {
        const res = await service.get(
          `${process.env.REACT_APP_URL}/${process.env.REACT_APP_API}/orders?populate=*`
        );
        const filteredOrders = res?.data?.filter(
          (order) => order.attributes.store.data.id === row?.storeId
        );
        setOrders(filteredOrders);
      } catch (err) {
        console.error(err);
      }
    };

    loadOrders();
  }, [row?.storeId]);
  console.log("row", row);
  return (
    <Box
      sx={{
        background: "white",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <Box sx={{ textAlign: "right" }}>
        <IconButton aria-label="edit" onClick={() => handleClose()}>
          <CrossIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          fontSize: "24px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Balance Sheet
      </Box>
      <Box
        sx={{
          fontSize: "24px",
          fontWeight: "bold",
          textAlign: "Left",
          padding: "20px 40px",
        }}
      >
        {row?.storeName}
      </Box>
      <Box
        sx={{
          fontSize: "18px",
          fontWeight: "normal",
          textAlign: "Left",
          padding: "0 40px",
        }}
      >
        {" "}
        <Typography>23 Smith's Avenue, Burlington, ON</Typography>
        <Typography>Phone: 416-999-9999</Typography>
      </Box>
      <Box
        sx={{
          fontSize: "18px",
          fontWeight: "normal",
          textAlign: "Left",
          padding: "20px 40px",
        }}
      >
        <TableContainer component={Paper} sx={{ width: 400 }}>
          <Table aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#dc3b05", color: "white" }}>
              <TableRow>
                <TableCell align="left" sx={{ color: "white" }}>
                  Period
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Date Issued
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Business Number
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  textAlign: "center",
                }}
              >
                <TableCell component="th" scope="row">
                  Dec, 2024
                </TableCell>
                <TableCell component="th" scope="row">
                  1-jan-2024
                </TableCell>

                <TableCell component="th" scope="row">
                  N/A
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ padding: "0 40px" }}>
        <Box
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "Left",
            padding: "10px 0px",
          }}
        >
          Orders Accepted
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#dc3b05", color: "white" }}>
              <TableRow>
                <TableCell align="left" sx={{ color: "white" }}>
                  Order#
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Date
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Type
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Item
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Quantity
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Notes
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Org. Price
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Disc. %
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Final Price
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Comission
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Tip
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Delivery/ Pickup
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Credit
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Debit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.length ? (
                orders.map((row) => (
                  <TableRow
                    key={row.Id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      textAlign: "center",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.Id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {" "}
                      {new Date(row.attributes.deliveryDate).toLocaleDateString(
                        "en-US"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      -
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {" "}
                      {row.attributes.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.attributes.quantity}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      -
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.attributes.totalAmount}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.attributes.disocunt}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.attributes.Subtotal}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      -
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.attributes.Tip}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.attributes.delivery_types.data.map(
                        (deliveryType, index) => (
                          <span key={index}>
                            {deliveryType.attributes.name}
                            {index !==
                              row.attributes.delivery_types.data.length - 1 &&
                              ", "}
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      -
                    </TableCell>
                    <TableCell component="th" scope="row">
                      -
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">no records</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ padding: "0 40px" }}>
        <Box
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "Left",
            padding: "10px 0px",
          }}
        >
          Orders Refunded
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#dc3b05", color: "white" }}>
              <TableRow>
                <TableCell align="left" sx={{ color: "white" }}>
                  Order#
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Date
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Type
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Item
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Quantity
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Notes
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Org. Price
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Disc. %
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Final Price
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  %Refund
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}></TableCell>
                <TableCell align="left" sx={{ color: "white" }}></TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Credit
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Debit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.length ? (
                orders.map((row) => (
                  <TableRow
                    key={row.Id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      textAlign: "center",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.Id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {" "}
                      {new Date(row.attributes.deliveryDate).toLocaleDateString(
                        "en-US"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      -
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {" "}
                      {row.attributes.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.attributes.quantity}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      -
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.attributes.totalAmount}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.attributes.disocunt}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.attributes.Subtotal}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      -
                    </TableCell>

                    <TableCell component="th" scope="row">
                      -
                    </TableCell>

                    <TableCell component="th" scope="row">
                      -
                    </TableCell>
                    <TableCell component="th" scope="row">
                      -
                    </TableCell>
                    <TableCell component="th" scope="row">
                      -
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">no records</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ padding: "0 40px" }}>
        <Box
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "Left",
            padding: "10px 0px",
          }}
        >
          Accounnt Balance for &lt; Period &gt;
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  textAlign: "center",
                }}
              >
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>

                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>

                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>

                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Total Credit
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Total Debit
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  textAlign: "center",
                }}
              >
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>

                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>

                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>

                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row">
                  $102.80
                </TableCell>
                <TableCell component="th" scope="row">
                  $6.50
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        sx={{
          fontSize: "18px",
          fontWeight: "normal",
          display: "flex",
          alignItem: "center",
          justifyContent: "space-between",
          padding: "40px",
          flexWrap: "wrap",
        }}
      >
        <Box>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontSize: "14px",
              color: "red",
              fontWeight: "bold",
              maxWidth: "800px",
            }}
          >
            If you find any disrepency in this balance sheet, please call us at
            xxx-xxx-xxxx at least 48 hours before the payment transfer date
            mentioned herein. otherwise, any adjustment, if agreed by both the
            parties, will be transferred in the next payment cycle. Thank you.
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "Left",
            }}
          >
            Total Payable: $96.30
          </Box>
          <Box
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "Left",
            }}
          >
            {" "}
            Payment Date: 1/8/2024
          </Box>
          <Box
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "Left",
            }}
          >
            Bank Acc: xxxx-234
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentCycleDetail;
